.register {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(228deg, #0279a4 -22.97%, #473198 120.53%);
    height: 100vh;
    position: relative;
}

.register .foto {
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 111;
}

.register .bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.register .bottom img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
}

.register .box {
    position: relative;
    z-index: 1;
    background-color: rgba(0, 0, 0, .6);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.register .box h1.top {
    text-align: center;
    color: crimson;
    font-size: 45px;
    font-weight: 900;
    letter-spacing: 1px;
    line-height: 40px;
    margin-bottom: 35px;
    text-shadow: 1px 1px 1px black;
    font-family: Montserrat, sans-serif;
    text-transform: uppercase;
}

.register .box h1.h1_register {
    color: #fff;
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 20px;
    font-family: Montserrat, sans-serif;
    text-shadow: 1px 1px 1px black;
    text-align: center;
}

.register .box h1.h1_register span {
    color: #fec544;
    font-weight: 900;
    font-size: 40px;
}

.register .box a {
    background-color: #fec544;
    border: none;
    border-radius: 7px;
    box-shadow: 0px 0px 2px #000;
    color: #101624;
    font-size: 18px;
    font-weight: 700;
    padding: 15px 70px;
    text-decoration: none;
    width: 350px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 5px;
}

.register .box a i.fa-regular {
    font-size: 20px;
    animation: tickAnimation 2s infinite;
}

@keyframes tickAnimation {

    0%,
    100% {
        transform: scale(0.9);
    }

    50% {
        transform: scale(1);
    }
}

.register .box p.p_register {
    text-align: center;
    color: white;
    font-size: 15px;
    font-family: Montserrat, sans-serif;
    text-shadow: 1px 1px 1px black;
    font-weight: 400;
}

.register .box p.p_register span {
    color: #30a8ff;
    font-size: 15px;
    font-weight: 900;
    text-transform: uppercase;
}


@media(max-width:767px) {
    .register .box h1.h1_register {
        font-size: 30px;
    }
}

@media(max-width:575px) {
    .register .foto {
        width: 100%;
        right: 0px;
        left: 0px;
    }

    .register .foto img {
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .register .box h1.h1_register {
        font-size: 20px;
        line-height: 23px;
    }

    .register .box h1.h1_register span {
        font-size: 22px;
    }

    .register .box a {
        width: 250px;
        padding: 15px 25px;
    }

    .register .box p.p_register {
        font-size: 15px;
    }

    .register .box p.p_register span {
        font-size: 13px;
    }

    .register .box h1.top {
        font-size: 30px;
        line-height: 35px;
        margin-bottom: 20px;
    }
}
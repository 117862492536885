*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
img,
svg,
video{
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

body{
  background-color: #f4f7fd;
}